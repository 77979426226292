import { observe, makeAutoObservable } from "mobx";
import { map } from 'lodash';

import axios from 'axios';

class QuoteStore {
  // Always access required component data through getters to safe-guard against TypeErrors.
  lsQuotesItemKey = 'bq.quotes';
  quoteReference = null;
  data = null;
  constructor(rootStore, endpoints, referenceKey = 'quoteReference') {
    makeAutoObservable(this);
    this.rootStore = rootStore;

    // accepts three endpoints (quickQuote, fullQuote, addOns)
    this.endpoints = endpoints;
    this.quoteReference = null;
    this.referenceKey = referenceKey;
    this.productReference = null;

    // merged responses from server across flow; essentially a localStorage
    // copy of database quote record.
    this.data = {};
    this.initialized = false;
    this.loading = false;
    this.error = false;
    this.notFound = false;

    // Attempt to load quote from localStorage when quoteReference is changed.
    observe(this, "quoteReference", change => {
      if (change.newValue !== change.oldValue) {
        const quote = this.localStorage[change.newValue];
        
        if (quote === undefined) {
          this.notFound = true;
        } else {
          this.data = quote;
        }
      }
    });

    // Save quote data to localStorage when updated.
    observe(this, "data", change => {
      // Check if data contains quote reference, before trying to save,
      // otherwise null/undefined keys are created.
      if (change.newValue !== undefined && change.newValue[this.referenceKey]) {
        let quotes = this.localStorage;
        quotes[change.newValue[this.referenceKey]] = change.newValue;
        const storage = JSON.stringify(quotes);
        window.localStorage.setItem(this.lsQuotesItemKey, storage);
      }
    });
  }

  async fetch(endpoint, requestData, requestType = 'post') {
    /**
     * Base function for directly calling API.
     * 
     * Callers are responsible for handling how this.data is mutated after
     * requests.
     */
    this.loading = true;

    // Don't send server-specific response data.
    const { quotes, availableCoverTypes, ...data } = this.data;

    return axios[requestType](endpoint, { ...data, ...requestData })
      .then((response) => {
        this.quoteRefNum = response.data.quoteReference;
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        this.error = true;
        return Promise.reject(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  getQuickQuote(requestData, requestType = 'post') {
    return this.fetch(this.endpoints.quickQuote, requestData, requestType)
      .then((data) => {
        this.data = { ...this.data, ...data };
        return Promise.resolve(this.data);
      });
  }

  getFullQuote(requestData, requestType = 'patch') {
    return this.fetch(this.endpoints.fullQuote, requestData, requestType)
      .then((data) => {
        this.data = { ...this.data, ...data };
        return Promise.resolve(this.data);
      });
  }

  updateQuote(requestData, productId, completed = false) {
    const requestDetails = {
        quoteId: this.id,
        quoteReference: this.quoteReference,
        phoneNumber: this.data.phoneNumber,
        emailAddress: this.data.emailAddress,
        productId: productId,
        completed,
        additionalFeatures: map(requestData, (val, key) => ({ featureId: Number(key), selectedOptions: val })),
    }

    return this.fetch(this.endpoints.addOns, requestDetails, 'patch')
      .then(data => {
        if (completed) {
          this.deleteQuote(requestDetails.quoteReference);
          this.clearStore();
          return Promise.resolve(null);
        } else {
          let productIndex = this.data.quotes.findIndex(i => i.id === this.selectedProduct.id);
          this.data.quotes[productIndex] = { ...this.selectedProduct, ...data };
          return Promise.resolve(this.data);
        }
      })
      .catch(() => Promise.reject(null));
  }

  confirmQuote(productId = null) {
    const requestData = {
      quoteId: this.id,
      quoteReference: this.quoteReference,
      phoneNumber: this.data.phoneNumber,
      emailAddress: this.data.emailAddress,
      productId,
    };

    return this.fetch(this.endpoints.confirm, requestData, "patch")
      .then(() => {
        this.deleteQuote(requestData.quoteReference);
        this.clearStore();
        return Promise.resolve(null);
      });
  }

  deleteQuote(quoteReference) {
    let quotes = this.localStorage;
    delete quotes[quoteReference];
    const storage = JSON.stringify(quotes);
    window.localStorage.setItem(this.lsQuotesItemKey, storage);
  }

  clearStore() {
    this.data = {};
    this.quoteReference = null;
    this.productReference = null;
    this.loading = false;
    this.error = false;
    this.notFound = false;
  }

  get localStorage() {
    const storage = window.localStorage.getItem(this.lsQuotesItemKey);
    return JSON.parse(storage || "{}");
  }

  get id() {
    return this.data.id || null;
  }

  get annualPremium() {
    if (this.selectedProduct) {
      return this.selectedProduct.annualPremium || null;
    }

    return this.data.annualPremium || null;
  }

  get monthlyPremium() {
    if (this.selectedProduct) {
      return this.selectedProduct.monthlyPremium || null;
    }

    return this.data.monthlyPremium || null;
  }

  get stampDuty() {
    if (this.selectedProduct) {
      return this.selectedProduct.stampDuty || null;
    }

    return this.data.stampDuty || null;
  }

  get products() {
    if (this.data.quotes) {
      return this.data.quotes.slice().sort((a, b) => a.annualPremium - b.annualPremium);
    }
    return [];
  }

  get availableCoverTypes() {
    return this.data.availableCoverTypes || [];
  }

  get selectedProduct() {
    if (this.data.quotes && this.productReference) {
      return this.data.quotes.find(i => i.id === Number(this.productReference));
    }
    return null;
  }

  get errors() {
    return this.data.errors || [];
  }
}
export default QuoteStore