import { makeAutoObservable } from "mobx";
import LookupStore from './lookup-store';
import QuoteStore from './quote-store';
import appConstants from './constants';

import engineSizes from '../data/engineSize';
import months from '../data/months';
import yearsOfManufacture from '../data/yearsOfManufacture';
import vehicleTypes from '../data/vehicleType';
import vehicleUses from '../data/vehicleUse';
import yearsDriving from '../data/yearDriving';
import driverAges from '../data/driverAge';
import claimFreeYears from '../data/claimFreeYears';
import parishes from '../data/parishes';

const defaultQuickQuoteOptions = {
  engineSizes,
  vehicleUses,
  vehicleTypes,
  yearsOfManufacture: yearsOfManufacture(),
  yearsDriving,
  driverAges,
  claimFreeYears,
  parishes
};

const defaultFullQuoteOptions = {
  months,
  parishes,
  vehicleUses,
  vehicleModels: [],
  vehicleMakes: [],
  occupations: []
};

class MotorStore {
  constructor() {
    makeAutoObservable(this);
    const { LOOK_UPS, QUOTES, ADD_ONS, CONFIRM } = appConstants.MOTOR;

    this.lookups = {
      quickQuote: new LookupStore(this, LOOK_UPS.QUICK_QUOTE, defaultQuickQuoteOptions),
      fullQuote: new LookupStore(this, LOOK_UPS.FULL_QUOTE, defaultFullQuoteOptions),
    };
    this.quote = new QuoteStore(this, { quickQuote: QUOTES.QUICK, fullQuote: QUOTES.FULL_QUOTE, addOns: ADD_ONS, confirm: CONFIRM });
  }

  getQuickQuote(data) {
    return this.quote.getQuickQuote({
      vehicles: [data.vehicle],
      drivers: [{ ...data.driver, fullName: data.fullName }],
      claimFreeYears: data.claimFreeYears,
      phoneNumber: data.phoneNumber,
      emailAddress: data.emailAddress,
    });
  }

  getFormValues() {
    const { data, availableCoverTypes } = this.quote;

    const primaryVehicle = data.vehicles[0];
    const barpEligible = data.drivers[0].age >= 50;

    const formValues = {
      coverType: data.coverType || availableCoverTypes[0].type.id,
      eligibleForBarp: (barpEligible) ? data.drivers[0].eligibleForBarp : false,
      creditUnionMember: data.drivers[0].creditUnionMember,
      additionalDetails: {
        ...data.drivers[0],
        coverStartMonth: data.coverStartMonth,
        vehicleUse: primaryVehicle.use,
        vehicleMake: primaryVehicle.make,
        vehicleModel: primaryVehicle.model,
        vehicleIsLeftHand: primaryVehicle.isLeftHandDrive,
        accidentInLast5Years: primaryVehicle.accidentInTheLastFiveYears,
      },
      drivers: data.drivers.slice(1)
    }

    return formValues;
  }

  getFullQuote(requestDetails) {
    const { data } = this.quote;

    const request = {
      coverStartMonth: requestDetails.additionalDetails.coverStartMonth,
      coverType: requestDetails.coverType,
      drivers: [
        {
          ...data.drivers[0],
          firstName: requestDetails.additionalDetails.firstName,
          lastName: requestDetails.additionalDetails.lastName,
          dateOfBirth: requestDetails.additionalDetails.dateOfBirth,
          licenseIssueDate: requestDetails.additionalDetails.licenseIssueDate,
          eligibleForBarp: requestDetails.eligibleForBarp,
          creditUnionMember: requestDetails.creditUnionMember,
          occupation: requestDetails.additionalDetails.occupation,
          parish: requestDetails.additionalDetails.parish,
        },
        ...requestDetails.drivers
      ],
      vehicles: [{
        ...data.vehicles[0],
        make: requestDetails.additionalDetails.vehicleMake,
        model: requestDetails.additionalDetails.vehicleModel,
        isLeftHandDrive: requestDetails.additionalDetails.vehicleIsLeftHand,
        accidentInTheLastFiveYears: requestDetails.additionalDetails.accidentInLast5Years,
      }
    ]}

    return this.quote.getFullQuote(request, 'patch');
  }

  async getQuoteSummary(quoteRefNum) {
    const fullQuoteLookups = await this.lookups.fullQuote.get();
    const quickQuoteLookups = await this.lookups.quickQuote.get();
    // await motorStore.fullQuote.quote.selectProductQuote(match.params.quoteProductRef);

    const { data } = this.quote;
    const primaryVehicle = data.vehicles[0];

    // TODO: move to motor store; should call store for
    const vehicleType = quickQuoteLookups.vehicleTypes.find(i => i.id === Number(primaryVehicle.vehicleType));
    const vehicleUse = quickQuoteLookups.vehicleUses.find(i => i.id === Number(primaryVehicle.use));
    const noClaimDiscount = quickQuoteLookups.claimFreeYears.find(i => i.id === Number(data.claimFreeYears));
    const engineSize = quickQuoteLookups.engineSizes.find(i => i.id === Number(primaryVehicle.engineSize));
    const vehicleMake = fullQuoteLookups.vehicleMakes.find(i => i.id === Number(primaryVehicle.make));
    const vehicleModel = fullQuoteLookups.vehicleModels.find(i => i.id === Number(primaryVehicle.model));
    const coverType = this.quote.availableCoverTypes.find(i => i.type.id === Number(data.coverType));

    return [
      { title: 'Type of Cover', description: coverType.type.name },
      { title: 'Vehicle Use', description: vehicleUse.name },
      { title: 'Vehicle', description: `${primaryVehicle.yearOfManufacture} ${vehicleMake.make} ${vehicleModel.model} ${engineSize.sizeRange}cc` },
      { title: 'No Claim Discount', description: `${noClaimDiscount.name} Years` },
      // { title: 'Vehicle Value', description: formatCurrency(requestDetails.vehicles[0].value) },
      { title: 'Additional Drivers', description: (data.drivers.length > 1) ? 'Yes' : 'No' },
      { title: 'Vehicle Type', description: vehicleType.name },
    ];
  }
}
export default MotorStore