import React, { Component, Suspense, lazy } from "react";
import { inject } from "mobx-react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import TagManager from "react-gtm-module";
// import * as GA from './utils/googleAnalytics';
import Header from "./containers/Header/Header";
import Footer from "./containers/Footer/Footer";
import ROUTES from "./routes";
import { Container } from "reactstrap";

const Home = lazy(() => import("./containers/Home/Home"));

const MotorInsurance = lazy(() =>
  import("./containers/MotorInsurance/MotorInsurance")
);
const MotorProductList = lazy(() =>
  import("./containers/MotorInsurance/MotorProductList")
);
const MotorFullQuote = lazy(() =>
  import("./containers/MotorInsurance/MotorFullQuote")
);
const MotorProductDetails = lazy(() =>
  import("./containers/MotorInsurance/MotorProductDetails")
);

const HomeInsurance = lazy(() =>
  import("./containers/HomeInsurance/HomeInsurance")
);
const HomeFullQuote = lazy(() =>
  import("./containers/HomeInsurance/HomeFullQuote")
);
const HomeProductList = lazy(() =>
  import("./containers/HomeInsurance/HomeProductList")
);
const HomeProductDetails = lazy(() =>
  import("./containers/HomeInsurance/HomeProductDetails")
);

const LifeInsurance = lazy(() =>
  import("./containers/LifeInsurance/LifeInsurance")
);
const TermLife = lazy(() => import("./containers/TermLife/TermLife"));
const SmallBusiness = lazy(() =>
  import("./containers/SmallBusiness/SmallBusiness")
);
const GroupLife = lazy(() => import("./containers/GroupLife/GroupLife"));
const GroupHealth = lazy(() => import("./containers/GroupHealth/GroupHealth"));

const PrivacyPolicy = lazy(() => import("./containers/Legal/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./containers/Legal/TermsOfUse"));
const AboutUs = lazy(() => import("./containers/AboutUs/AboutUs"));

const ClaimsSupport = lazy(() =>
  import("./containers/CustomerCare/ClaimsSupport")
);
const ContactUs = lazy(() => import("./containers/CustomerCare/ContactUs"));
const Faq = lazy(() => import("./containers/CustomerCare/Faq"));

const InternalError = lazy(() =>
  import("./containers/ErrorPages/InternalError")
);
const NotFound = lazy(() => import("./containers/ErrorPages/NotFound"));
const QuoteCompleted = lazy(() => import("./components/QuoteCompleted"));
const BARPHome = lazy(() => import("./components/BARPHome/BARPHome"));
const BARPPaymentOnline = lazy(() => import("./components/BARPPaymentOnline"));

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_TAG,
};

TagManager.initialize(tagManagerArgs);

class App extends Component {
  componentDidMount() {
    const { contentStore } = this.props;
    contentStore.fetchGlobalBlocks();
  }

  render() {
    return (
      <Router>
        <div className="page-container d-flex flex-column">
          <Header />
          <Suspense
            fallback={
              <Container
                fluid
                className="d-flex align-items-center justify-content-center flex-grow-1"
              >
                <h4>Loading...</h4>
              </Container>
            }
          >
            <Switch>
              <Route
                exact
                path={ROUTES.motorInsurance}
                render={() => <MotorInsurance />}
              />
              <Route
                exact
                path={ROUTES.motorFullQuote}
                render={() => <MotorFullQuote />}
              />
              <Route
                exact
                path={ROUTES.motorProductList}
                render={() => <MotorProductList />}
              />
              <Route
                exact
                path={ROUTES.motorProductDetails}
                render={() => <MotorProductDetails />}
              />
              <Route
                exact
                path={ROUTES.motorCompleted}
                render={() => <QuoteCompleted />}
              />

              <Route
                exact
                path={ROUTES.homeInsurance}
                render={() => <HomeInsurance />}
              />
              <Route
                exact
                path={ROUTES.homeFullQuote}
                render={() => <HomeFullQuote />}
              />
              <Route
                exact
                path={ROUTES.homeProductList}
                render={() => <HomeProductList />}
              />
              <Route
                exact
                path={ROUTES.homeProductDetails}
                render={() => <HomeProductDetails />}
              />
              <Route
                exact
                path={ROUTES.homeCompleted}
                render={() => <QuoteCompleted />}
              />

              <Route
                path={ROUTES.smallBusiness}
                render={() => <SmallBusiness />}
              />
              <Route path={ROUTES.groupLife} render={() => <GroupLife />} />
              <Route path={ROUTES.groupHealth} render={() => <GroupHealth />} />
              <Route
                exact
                path={ROUTES.lifeInsurance}
                render={() => <LifeInsurance />}
              />
              <Route
                path={ROUTES.BARPPaymentOnline}
                exact
                render={() => <BARPPaymentOnline />}
              />
              <Route path={ROUTES.BARPHome} render={() => <BARPHome />} />
              {/* NOTE: exclude exact prop so that parent component renders with child routes */}
              <Route path={ROUTES.termLife} render={() => <TermLife />} />

              <Route
                exact
                path={ROUTES.privacyPolicy}
                render={() => <PrivacyPolicy />}
              />
              <Route
                exact
                path={ROUTES.termsOfUse}
                render={() => <TermsOfUse />}
              />
              <Route exact path={ROUTES.aboutUs} render={() => <AboutUs />} />
              <Route
                exact
                path={ROUTES.claimsSupport}
                render={() => <ClaimsSupport />}
              />
              <Route
                exact
                path={ROUTES.contactUs}
                render={() => <ContactUs />}
              />
              <Route exact path={ROUTES.faq} render={() => <Faq />} />
              <Route
                exact
                path={ROUTES.internalError}
                render={() => <InternalError />}
              />
              <Route exact path={ROUTES.index} render={() => <Home />} />
              <Route render={() => <NotFound />} />
            </Switch>
          </Suspense>
          <Footer />
        </div>
        {/* <GA.PageView /> */}
      </Router>
    );
  }
}

export default inject("contentStore")(App);
