import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import appConstants from './constants';


class CallRequestStore {
  constructor() {
    makeAutoObservable(this);
    this.productOptions = []
    this.fetched = false;
  }

  async getOptions() {
    if (!this.fetched) {
      try {
        const { data } = await axios.get(appConstants.PRODUCT_LIST);
        this.productOptions = data;
        return this.productOptions;
      } catch (error) {
        console.error('Cannot get product categories', error);
      }
    }
    return this.productOptions;
  }

  async post(requestData) {
    try {
      await axios.post(appConstants.CALL_REQUEST, requestData);
      return true;
    } catch (error) {
      console.error(error);
    }
  }
}
export default CallRequestStore