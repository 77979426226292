
import { makeAutoObservable } from "mobx";

class MarketingMessageStore {
  constructor() {
    makeAutoObservable(this);
  }

  async getMarketingMessage(messageKey) {
    return new Promise((resolve, rej) => {
      return resolve({
        key: messageKey,
        title: null,
        message: ''
      });
    });
  }
}
export default MarketingMessageStore