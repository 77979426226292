import { makeAutoObservable } from "mobx";

import QuoteStore from './quote-store';
import appConstants from './constants';


class GroupHealthStore {
  constructor() {
    makeAutoObservable(this);
    const { QUOTES } = appConstants.GROUP_HEALTH;

    this.quote = new QuoteStore(this, { quickQuote: QUOTES.QUICK });
  }

  async getQuickQuote(data) {
    return await this.quote.getQuickQuote(data);
  }
}
export default GroupHealthStore