/**
* NOTES
* Don't use DropdownItem component for DropdownMenu children as it doesn't work with IE.
*/

import React from 'react';
import { Nav, NavItem, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';

function Menu({ isOpen }) {
  return (
    <Collapse isOpen={isOpen} navbar className={styles['navbar-collapse']}>
      <Nav className={`${styles['navbar-nav']} ml-auto`} navbar>
        <NavItem className={`${styles['nav-item']} ${styles['nav-header']} d-md-none`}>Products <i className="fas fa-angle-down"></i></NavItem>

        <NavItem className={styles['nav-item']}>
          <Link className={styles['nav-link']} to="/motor-insurance/">Car <span className="d-md-none">Insurance</span></Link>
        </NavItem>

        <NavItem className={styles['nav-item']}>
          <Link className={styles['nav-link']} to="/home-insurance/">Home <span className="d-md-none">Insurance</span></Link>
        </NavItem>

        <UncontrolledDropdown nav className={styles['nav-item']}>
          <DropdownToggle nav caret className={`${styles['nav-link']}`}>
            Business <span className="d-inline d-md-none">Insurance</span>
          </DropdownToggle>

          {/* Need to disable styling by poppers so taht mobile dropdown menu can be displayed inline with rest of menu. */}
          <DropdownMenu className={styles['dropdown-menu']} modifiers={{
            computeStyle: {
              enabled: false
            },
            applyStyle: {
              enabled: false,
            }
          }}>
            <div className={`${styles['dropdown-item']} dropdown-item`}>
              <NavItem>
                <Link className={styles['nav-link']} to="/business-insurance/small-business">
                  Small Business <span className="d-none d-md-inline">Insurance</span>
                </Link>
              </NavItem>
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>

        <UncontrolledDropdown nav className={`${styles['nav-item']} d-none d-md-inline`}>
          <DropdownToggle nav caret className={`${styles['nav-link']}`}>
            Customer Care
          </DropdownToggle>
          <DropdownMenu className={styles['dropdown-menu']}>
            <div className={`${styles['dropdown-item']} dropdown-item`}>
              <NavItem>
                <Link className={styles['nav-link']} to="/customer-care/claims-support">
                  Claims Support
                </Link>
              </NavItem>
            </div>

            <div className={`${styles['dropdown-item']} dropdown-item`}>
              <NavItem>
                <Link className={styles['nav-link']} to="/customer-care/faq">
                  FAQ
                </Link>
              </NavItem>
            </div>

            <div className={`${styles['dropdown-item']} dropdown-item`}>
              <NavItem>
                <Link className={styles['nav-link']} to="/customer-care/contact-us">
                  Contact Us
                </Link>
              </NavItem>
            </div>
            <div className={`${styles['dropdown-item']} dropdown-item`}>
              <NavItem>
                <Link className={styles['nav-link']} to="/payments">
                  Payments
                </Link>
              </NavItem>
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>

        {/* <UncontrolledDropdown nav className={styles['nav-item']}>
          <DropdownToggle nav caret className={`${styles['nav-link']} d-none d-md-block`}>
            Other Products
          </DropdownToggle>
          <DropdownMenu className={styles['dropdown-menu']}>
            <DropdownItem className={styles['dropdown-item']}>Travel Insurance</DropdownItem>
            <DropdownItem className={styles['dropdown-item']}>
              <NavItem onClick={onClick}>
                <Link className={styles['nav-link']} to="/life-insurance/">
                Life &amp; Health Insurance
              </Link>
              </NavItem>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown> */}

        <NavItem className={`${styles['nav-item']} ${styles['nav-header']}`}>
          <Link className={styles['nav-link']} to="/about-us/">About Us</Link>
        </NavItem>
      </Nav>
    </Collapse>
  )
}

export default Menu;
