import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Navbar, NavItem, Nav } from 'reactstrap';
import styles from './Header.module.scss';
import { Link } from 'react-router-dom';

import Menu from './Menu';

import BestQuoteLogo from "../../images/bestquote_logo.svg";
import BestQuoteLogoWhite from "../../images/bestquote_logo_white.svg";

function Header() {
  const [isOpen, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => setOpen(false), [location.pathname])

  return (
    <Navbar className={`${styles['navbar']} sticky-top`} light expand="md">
      <Container>
        <Nav navbar className="d-md-none">
          <NavItem onClick={() => setOpen(state => !state)}>
            <i className={`${styles['navbar-toggler-icon']} fas ${(isOpen) ? 'fa-times' : 'fa-bars'}`} />
          </NavItem>
        </Nav>

        <Link to="/">
          <picture>
            <source media="(max-width: 767px)" srcSet={BestQuoteLogoWhite} />
            <source media="(min-width: 768px)" srcSet={BestQuoteLogo} />
            <img src={BestQuoteLogo} alt="BestQuote - Big Insurance Savings" width="138" />
          </picture>
        </Link>

        <Menu isOpen={isOpen} />

        <Nav navbar>
          <NavItem>
            <a className={`${styles['nav-link']} ${styles['phone']}`} href="tel:243-6521">
              <i className="fas fa-phone-volume" /> <span className="d-none d-md-inline">243-6521</span>
            </a>
          </NavItem>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default Header;
