import React from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';

function List({ heading, items, visibility }) {
  return (
    <Col className={visibility}>
      <h6 className="d-none d-md-block">{heading}</h6>
      <ul>
        {items.map(i => (
          <li key={i._uid}>
            {(i.routed) ? <Link to={i.url}>{i.text}</Link> : <a href={i.url} target={i.target}>{i.text}</a>}
          </li>
        ))}
      </ul>
    </Col>
  )
}

export default List;
