import { makeAutoObservable } from "mobx";
import LookupStore from './lookup-store';
import QuoteStore from './quote-store';
import appConstants from './constants';
import { formatCurrency } from '../utils/currencyFormatter';

import claimFreeYears from '../data/claimFreeYears';
import foundationTypes from '../data/foundationTypes';
import homeInsuranceTypes from '../data/homeInsuranceTypes';
import occupancyTypes from '../data/occupancyTypes';
import parishAreas from '../data/parishAreas';
import parishes from '../data/parishes';
import wallTypes from '../data/primaryMaterialTypes';
import propertyLocations from '../data/propertyLocations';
import roofTypes from '../data/roofTypes';

const defaultQuickQuoteOptions = {
  claimFreeYears,
  foundationTypes,
  homeInsuranceTypes,
  occupancyTypes,
  parishAreas,
  parishes,
  wallTypes,
  propertyLocations,
  roofTypes,
};

class HomeStore {
  constructor() {
    makeAutoObservable(this);
    const { LOOK_UPS } = appConstants.HOME;
    const { QUOTES, ADD_ONS, CONFIRM } = appConstants.HOME;

    this.lookups = {
      quickQuote: new LookupStore(this, LOOK_UPS.QUICK_QUOTE, defaultQuickQuoteOptions),
    };
    this.quote = new QuoteStore(this, { quickQuote: QUOTES.QUICK, fullQuote: QUOTES.FULL_QUOTE, addOns: ADD_ONS, confirm: CONFIRM });
  }

  getFormValues() {
    const { data, availableCoverTypes } = this.quote;

    return {
      coverType: data.coverType || availableCoverTypes[0].type.id,
      additionalDetails: {
        firstName: data.firstName,
        lastName: data.lastName,
        dateOfBirth: data.dateOfBirth || '',
        streetAddress: data.streetAddress || '',
      },
      eligibleForBarp: data.eligibleForBarp
    }
  }

  getFullQuote(requestDetails) {
    return this.quote.getFullQuote({
      coverType: requestDetails.coverType,
      eligibleForBarp: requestDetails.eligibleForBarp,
      ...requestDetails.additionalDetails,
    }, 'patch');
  }

  async getQuoteSummary(quoteRefNum) {
    const lookups = await this.lookups.quickQuote.get();
    const { data } = this.quote;

    let summary = [
      { title: 'Wall Type', description: lookups.wallTypes.find(i => i.id === data.propertyDetails.wallType).name },
      { title: 'Floor/Foundation Type', description: lookups.foundationTypes.find(i => i.id === data.propertyDetails.foundationType).name },
      { title: 'Property Location', description: lookups.propertyLocations.find(i => i.id === data.propertyDetails.propertyLocation).name },
      { title: 'Roof Type', description: lookups.roofTypes.find(i => i.id === data.propertyDetails.roofType).name },
      { title: 'Occupancy Type', description: lookups.occupancyTypes.find(i => i.id === data.propertyDetails.occupancyType).name },
    ]

    if (data.coverageDetails.buildingValue) {
      summary.splice(0, 0, { title: 'Building Value', description: formatCurrency(data.coverageDetails.buildingValue) })
    }

    if (data.coverageDetails.contentValue) {
      summary.splice(1, 0, { title: 'Content Value', description: formatCurrency(data.coverageDetails.contentValue) })
    }
    return summary;
  }
}
export default HomeStore