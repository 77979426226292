const constants = {
  API_HOST: process.env.REACT_APP_API_HOST_URL,
  MOTOR: {
    LOOK_UPS: {
      FULL_QUOTE: '/bestquote/api/motor/lookups/fullquote',
      QUICK_QUOTE: '/bestquote/api/motor/lookups/quickquote'
    },
    QUOTES: {
      QUICK: '/bestquote/api/barbados/motor/quickquote',
      FULL_QUOTE: '/bestquote/api/barbados/motor/fullquote'
    },
    ADD_ONS: '/bestquote/api/motor/addfeatures',
    CONFIRM: '/bestquote/api/motor/confirm',
  },
  BARP_MOTOR: {
    ENUMS: '/car/enums/',
    LEAD: {
      CREATE: '/car/',
      UPDATE: (reference) => `/car/${reference}/`
    },
    QUOTES: {
      FULL_QUOTE: '/leads/premiums/',
      UPDATE: (reference) => `/leads/${reference}/`
    },
  },
  BARP_HOME: {
    ENUMS: '/home/enums/',
    LEAD: {
      CREATE: '/home/',
      UPDATE: (reference) => `/home/${reference}/`
    },
    QUOTES: {
      CREATE: '/leads/premiums/',
      UPDATE: (reference) => `/leads/${reference}/`
    },
  },
  HOME: {
    LOOK_UPS: {
      QUICK_QUOTE: '/bestquote/api/home/lookups/quickquote'
    },
    QUOTES: {
      QUICK: '/bestquote/api/home/quickquote',
      FULL_QUOTE: '/bestquote/api/home/fullquote'
    },
    ADD_ONS: '/bestquote/api/home/addfeatures',
    CONFIRM: '/bestquote/api/home/confirm',
  },
  LIFE: {
    LOOK_UPS: {
      QUICK_QUOTE: '/bestquote/api/life/lookups/quickquote'
    },
    QUOTES: {
      QUICK: '/bestquote/api/life/quickquote'
    },
  },
  BUSINESS: {
    QUOTES: {
      QUICK: '/bestquote/api/small_business/quickquote'
    },
  },
  GROUP_LIFE: {
    QUOTES: {
      QUICK: '/bestquote/api/group_life/quickquote'
    },
  },
  GROUP_HEALTH: {
    QUOTES: {
      QUICK: '/bestquote/api/group_health/quickquote'
    },
  },
  PRODUCT_LIST: '/bestquote/api/products',
  CALL_REQUEST: '/bestquote/api/call_request',
};

export default constants;
