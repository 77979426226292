import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Row, Col } from 'reactstrap';
import styles from './Footer.module.scss';
import List from './List';

function Footer({ contentStore }) {
  const blocks = contentStore.getBlocks('footer');
  const lists = blocks('lists', []);
  const copyright = blocks('copyright', null);

  return (
    <div className={styles['footer']}>
      <Container>
        <Row className={styles['links']}>
          {lists.map(i => <List key={i._uid} {...i} />)}
        </Row>
        <Row className={`${styles['copyright']} text-md-center`}>
          <Col>
            <p>{copyright}</p>
          </Col>
        </Row>
        <Row className={`${styles['attribution']} text-center`}>
          <Col>
            <p>Built by <a href="https://www.scggrowthpartners.com/">SCG Digital</a></p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default inject('contentStore')(observer(Footer));
