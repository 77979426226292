import { makeAutoObservable } from "mobx";

import LookupStore from './lookup-store';
import QuoteStore from './quote-store';
import appConstants from './constants';

const defaultQuickQuoteOptions = {};

class LifeStore {
  constructor() {
    makeAutoObservable(this);
    const { LOOK_UPS } = appConstants.LIFE;
    const { QUOTES } = appConstants.LIFE;

    this.quickQuote = {
      lookups: new LookupStore(this, LOOK_UPS.QUICK_QUOTE, defaultQuickQuoteOptions),
      quote: new QuoteStore(this, QUOTES.QUICK),
    };
  }
}
export default LifeStore