export const ROUTES = {
  index: "/",
  motorInsurance: "/motor-insurance",
  motorFullQuote: "/motor-insurance/:quoteRefNum",
  motorProductList: "/motor-insurance/:quoteRefNum/full-quote",
  motorProductDetails:
    "/motor-insurance/:quoteRefNum/full-quote/:quoteProductRef",
  motorCompleted:
    "/motor-insurance/:quoteRefNum/full-quote/:quoteProductRef/completed",

  homeInsurance: "/home-insurance",
  homeFullQuote: "/home-insurance/:quoteRefNum/",
  homeProductList: "/home-insurance/:quoteRefNum/full-quote",
  homeProductDetails:
    "/home-insurance/:quoteRefNum/full-quote/:quoteProductRef",
  homeCompleted:
    "/home-insurance/:quoteRefNum/full-quote/:quoteProductRef/completed",

  smallBusiness: "/business-insurance/small-business",
  groupLife: "/business-insurance/group-life",
  groupHealth: "/business-insurance/group-health",
  lifeInsurance: "/life-insurance",
  termLife: "/life-insurance/term-life",

  BARPHome: "/BARP",
  BARPPaymentOnline: "/payments",

  privacyPolicy: "/privacy-policy",
  termsOfUse: "/terms-of-use",
  aboutUs: "/about-us",

  claimsSupport: "/customer-care/claims-support",
  contactUs: "/customer-care/contact-us",
  faq: "/customer-care/faq",
  insurerContacts: "/customer-care/insurer-contacts",
  paymentOptions: "/customer-care/payment-options",
  renewals: "/customer-care/renewals",

  internalError: "/internal-error",
};

export default ROUTES;
